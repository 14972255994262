@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Montserrat, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-50 {
  z-index: 50;
}

.col-span-full {
  grid-column: 1 / -1;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.clear-both {
  clear: both;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-ml-80 {
  margin-left: -20rem;
}

.-ml-\[360px\] {
  margin-left: -360px;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-5\%\] {
  margin-top: -5%;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-4\/3 {
  aspect-ratio: 4 / 3;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.size-24 {
  width: 6rem;
  height: 6rem;
}

.size-3 {
  width: .75rem;
  height: .75rem;
}

.size-40 {
  width: 10rem;
  height: 10rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.h-12 {
  height: 3rem;
}

.h-24 {
  height: 6rem;
}

.h-6 {
  height: 1.5rem;
}

.h-80 {
  height: 20rem;
}

.h-\[110\%\] {
  height: 110%;
}

.h-\[640\] {
  height: 640px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-40 {
  min-height: 10rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-12 {
  width: 3rem;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-\[576px\] {
  width: 576px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.min-w-96 {
  min-width: 24rem;
}

.max-w-20 {
  max-width: 5rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-60 {
  max-width: 15rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-\[calc\(1440px-40\%\)\] {
  max-width: calc(1440px - 40%);
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-none {
  max-width: none;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.basis-\[calc\(\(100\%-10rem\)\/3\)\] {
  flex-basis: calc(33.3333% - 3.33333rem);
}

.basis-\[calc\(\(100\%-15rem\)\/4\)\] {
  flex-basis: calc(25% - 3.75rem);
}

.basis-\[calc\(\(100\%-20rem\)\/5\)\] {
  flex-basis: calc(20% - 4rem);
}

.basis-\[calc\(\(100\%-5rem\)\/2\)\] {
  flex-basis: calc(50% - 2.5rem);
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-12 {
  gap: 3rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-b-neutral-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-sic-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(59 87 144 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-20 {
  padding: 5rem;
}

.p-8 {
  padding: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-tight {
  line-height: 1.25;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.text-sic-blue {
  --tw-text-opacity: 1;
  color: rgb(59 87 144 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-slide {
  --tw-shadow: 0 0px 25px #00000026;
  --tw-shadow-colored: 0 0px 25px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

html {
  scroll-snap-type: y mandatory;
}

ul {
  margin-top: 1em;
  margin-bottom: 1.4em;
  margin-left: 20px;
  list-style-type: symbols(cyclic "✚");
  list-style-position: outside;
}

li {
  margin-bottom: .5em;
  padding-left: 10px;
}

li::marker {
  color: #e9b100;
}

p {
  margin-bottom: 1.5em;
}

p:last-child {
  margin-bottom: 0;
}

.markdown img {
  width: 100%;
  height: auto;
}

.first-line\:h-\[640\]:first-line {
  height: 640px;
}

.last\:rounded-xl:last-child {
  border-radius: .75rem;
}

.last\:bg-sic-blue:last-child {
  --tw-bg-opacity: 1;
  background-color: rgb(59 87 144 / var(--tw-bg-opacity));
}

.last\:px-4:last-child {
  padding-left: 1rem;
  padding-right: 1rem;
}

.last\:py-2:last-child {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.last\:text-sm:last-child {
  font-size: .875rem;
  line-height: 1.25rem;
}

.last\:font-bold:last-child {
  font-weight: 700;
}

.last\:uppercase:last-child {
  text-transform: uppercase;
}

.last\:text-white:last-child {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-\[calc\(50\%-0\.5rem\)\] {
    width: calc(50% - .5rem);
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:leading-tight {
    line-height: 1.25;
  }
}

@media (width >= 768px) {
  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-\[calc\(25\%-0\.75rem\)\] {
    width: calc(25% - .75rem);
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:aspect-auto {
    aspect-ratio: auto;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/7 {
    width: 14.2857%;
  }

  .lg\:w-1\/8 {
    width: 12.5%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-2\/6 {
    width: 33.3333%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-4\/6 {
    width: 66.6667%;
  }

  .lg\:w-\[calc\(50\%-1\.5rem\)\] {
    width: calc(50% - 1.5rem);
  }

  .lg\:max-w-screen-md {
    max-width: 768px;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-24 {
    padding-right: 6rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }
}

@media (width >= 1280px) {
  .xl\:w-1\/2 {
    width: 50%;
  }
}
/*# sourceMappingURL=index.f0b29853.css.map */
